import React, { useState } from "react"
import PropTypes from "prop-types"

// Components
import Typography from "@components/Typography"

// Material UI
import { useTheme, withStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"

const styles = (theme) => ({
    root: {
        display: "flex",
        overflow: "hidden",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(0),
    },
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    item: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(0, 0),
        marginTop: theme.spacing(4),
    },
    item_main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(0, 0),
    },
    title: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    tagline: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
    number: {},
    icon: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
})

function LandingPricing(props) {
    const { classes } = props

    const [checked, setChecked] = useState([0, 2])

    const [baseCost, setBaseCost] = useState("Free")
    const [proAddonsCost, setProAddonsCost] = useState(5)
    const [powerToolsCost, setProToolsCost] = useState("?")

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setProAddonsCost(newChecked.length * 2.5)
        setChecked(newChecked)
    }

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
        defaultMatches: true,
    })

    return (
        <section id="pricing" className={classes.root}>
            <Container maxWidth="md" className={classes.container}>
                <Typography
                    variant="h3"
                    marked="none"
                    className={classes.title}
                    component="h2"
                    align="center"
                >
                    How much does
                    <span style={{ color: "#ff3366" }}> it </span>
                    cost?
                </Typography>

                <Typography
                    className={classes.tagline}
                    component="h2"
                    variant="h5"
                    align="center"
                    noWrap
                >
                    choose what you need...
                </Typography>
                <div>
                    <Grid container spacing={isMobile ? 2 : 5}>
                        <Grid item xs={12} sm={4} style={{ order: isMobile ? 2 : 1 }}>
                            <div className={classes.item}>
                                <Typography color="secondary" variant="h4" align="center">
                                    Pro
                                </Typography>
                                <Typography
                                    color="secondary"
                                    variant="body1"
                                    align="center"
                                    noWrap
                                >
                                    (add-ons)
                                </Typography>
                                <span className={classes.icon}>
                                    <Typography
                                        display="inline"
                                        variant="h2"
                                        align="center"
                                        noWrap
                                    >
                                        {proAddonsCost ? `$${proAddonsCost}*` : "N/A"}
                                    </Typography>
                                    <Typography
                                        display="inline"
                                        variant="h5"
                                        align="center"
                                        noWrap
                                    >
                                        {proAddonsCost ? `/pcm` : ""}
                                    </Typography>
                                </span>
                                <List
                                    dense
                                    disablePadding
                                    style={{ minWidth: 200, alignContent: "center" }}
                                >
                                    {[
                                        {
                                            primary: "Import chapters",
                                            secondary: "from YouTube, text...",
                                            available: null,
                                            disabled: false,
                                        },
                                        {
                                            primary: "Export reactions",
                                            secondary: "to text, csv, Notion...",
                                            available: false,
                                            disabled: true,
                                        },
                                        {
                                            primary: "Private skips",
                                            secondary: "accessible only by you",
                                            available: null,
                                            disabled: false,
                                        },
                                        {
                                            primary: "Sync your reactions with",
                                            secondary: "Google Sheets, Airtable...",
                                            available: false,
                                            disabled: true,
                                        },
                                    ].map((value, i) => {
                                        const labelId = `pro-checkbox-list-label-${i}`
                                        return (
                                            <ListItem
                                                key={i}
                                                onClick={
                                                    value.disabled
                                                        ? () => null
                                                        : handleToggle(i)
                                                }
                                                style={{
                                                    padding: 0,
                                                    marginLeft: "30px",
                                                    cursor: "pointer",
                                                    userSelect: "none",
                                                }}
                                                disabled={value.disabled}
                                            >
                                                <ListItemIcon style={{ minWidth: 0 }}>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            value.available ??
                                                            checked.indexOf(i) !== -1
                                                        }
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{
                                                            "aria-labelledby": labelId,
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={labelId}
                                                    primary={value.primary}
                                                    secondary={value.secondary}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                {proAddonsCost ? (
                                    <Typography
                                        className={classes.tagline}
                                        variant="h6"
                                        align="center"
                                        noWrap
                                    >
                                        *currently FREE
                                    </Typography>
                                ) : null}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ order: isMobile ? 1 : 2 }}>
                            <div className={classes.item_main}>
                                <Typography color="secondary" variant="h3" align="center">
                                    SKIP
                                </Typography>
                                <Typography
                                    variant="h2"
                                    align="center"
                                    noWrap
                                    className={classes.icon}
                                >
                                    FREE
                                </Typography>
                                <List dense disablePadding style={{ minWidth: 200 }}>
                                    {[
                                        {
                                            primary: "Load unlimited content",
                                            secondary: "from YouTube, Vimeo, files...",
                                        },
                                        {
                                            primary: "Create your own channel",
                                            secondary: "curate your own lists",
                                        },
                                        {
                                            primary: "Semi-private skips",
                                            secondary: "accessible by link only",
                                        },
                                        {
                                            primary: "Mange your skips",
                                            secondary: "edit, customize, share...",
                                        },
                                    ].map((value, i) => {
                                        const labelId = `base-checkbox-list-label-${i}`
                                        return (
                                            <ListItem
                                                key={i}
                                                style={{
                                                    padding: 0,
                                                    marginLeft: "30px",
                                                    pointerEvents: "none",
                                                    userSelect: "none",
                                                }}
                                            >
                                                <ListItemIcon style={{ minWidth: 0 }}>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={true}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{
                                                            "aria-labelledby": labelId,
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={labelId}
                                                    primary={value.primary}
                                                    secondary={value.secondary}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ order: 3 }}>
                            <div className={classes.item}>
                                <Typography color="secondary" variant="h4" align="center">
                                    Power
                                </Typography>
                                <Typography
                                    color="secondary"
                                    variant="body1"
                                    align="center"
                                    noWrap
                                >
                                    (tools)
                                </Typography>
                                <span className={classes.icon}>
                                    <Typography
                                        display="inline"
                                        variant="h2"
                                        align="center"
                                        noWrap
                                    >
                                        $?
                                    </Typography>
                                    <Typography
                                        display="inline"
                                        variant="h5"
                                        align="center"
                                        noWrap
                                    >
                                        {proAddonsCost ? `/pcm` : ""}
                                    </Typography>
                                </span>
                                <List dense disablePadding style={{ minWidth: 200 }}>
                                    {[
                                        {
                                            primary: "Analytics dashboard",
                                            secondary: "insights, patterns...",
                                            available: true,
                                            disabled: false,
                                        },
                                        {
                                            primary: "Groups, memberships",
                                            secondary: "bulk creation, management",
                                            available: false,
                                            disabled: true,
                                        },
                                        {
                                            primary: "Accept payments",
                                            secondary: "Stripe, PayPal...",
                                            available: false,
                                            disabled: true,
                                        },
                                        {
                                            primary: "NLP/Machine Learning",
                                            available: false,
                                            disabled: true,
                                        },
                                    ].map((value, i) => {
                                        const labelId = `power-checkbox-list-label-${i}`
                                        return (
                                            <ListItem
                                                key={i}
                                                style={{
                                                    padding: 0,
                                                    marginLeft: "30px",
                                                    pointerEvents: "none",
                                                    userSelect: "none",
                                                }}
                                                disabled={value.disabled}
                                            >
                                                <ListItemIcon style={{ minWidth: 0 }}>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={value.available}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{
                                                            "aria-labelledby": labelId,
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={labelId}
                                                    primary={value.primary}
                                                    secondary={value.secondary}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </section>
    )
}

LandingPricing.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LandingPricing)
