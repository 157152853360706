export const SKIP_LOGO_SVG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/s-k-i-p.svg?alt=media&token=1154b20d-f0a0-48d2-afdf-2a0d55e37adb"
export const YOUTUBE_LOGO_SVG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/youtube.svg?alt=media&token=6f07e787-59a4-4088-9536-b5ed41bd92dd"
export const VIMEO_LOGO_SVG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/vimeo.svg?alt=media&token=aeb1cff6-b64d-4792-b501-29e289862260"
export const MP3_LOGO_SVG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/mp3.svg?alt=media&token=4acfd532-ae3a-4aa4-b74b-3c1bf754d07a"
export const CONTENT_LOGO_SVG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/content.svg?alt=media&token=31ff865d-7365-4f27-a0ef-9a462b419044"
export const SKIP_LOGO_PNG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/s-k-i-p.png?alt=media&token=4705a4e9-c2a1-4da2-b6ae-b6c7645f66f5"
export const SKIP_BANNER_HASH_PNG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/s-k-i-p-banner-hash.png?alt=media&token=23d1bc2b-7cb9-43ae-adf1-9979393ec78d"
export const SKIP_BANNER_LINK_PNG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/s-k-i-p-banner-link.png?alt=media&token=925446b2-7987-41c9-af7a-a1c938dc0df2"
export const SKIP_BANNER_WELCOME_PNG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/s-k-i-p-welcome.png?alt=media&token=fe5b4c8c-2a2d-4351-8de2-c4685d64b006"
export const SKIP_BANNER_PNG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/banners%2Fs-k-i-p-banner.png?alt=media&token=b7f0bfc5-f4b1-4b12-a6c6-c4e66316df4d"
export const SKIP_BACKGROUND_SVG =
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/backgrounds%2Fstacked-peaks-haikei99.svg?alt=media&token=49dcf2e6-d267-4247-8f5b-489d1f1ce94b"
export const USED_BY_LIST = [
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/logos%2Fhumble-associates.svg?alt=media&token=282d8967-8155-4e81-8ef2-d50aa64aaa78",
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/logos%2Fpub-comedy.svg?alt=media&token=fe2f8003-f523-4c93-84ec-a59b193b71e3",
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/logos%2Frelearntech.svg?alt=media&token=b8b85457-f0df-4181-9abe-6c8a05961cba",
    "https://firebasestorage.googleapis.com/v0/b/sttgbit-app.appspot.com/o/logos%2Fbart.svg?alt=media&token=325caacb-c0fa-41df-93bd-d90d9fbfbe9f",
]
