import React from "react"
import PropTypes from "prop-types"

// Material UI
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { useTheme, withStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

// Components
import Paper from "@components/Paper"

const styles = (theme) => ({
    root: {
        display: "flex",
        minHeight: "100vh",
        minWidth: "350px",
    },
    paper: {
        padding: theme.spacing(1, 1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 2),
        },
    },
})

function AppPanel(props) {
    const { children, classes } = props

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
        defaultMatches: true,
    })

    return (
        <div className={classes.root}>
            <Container maxWidth="xl">
                {/* <Box mt={2} mx={isMobile ? 0 : 0}> */}
                {/* <Paper className={classes.paper} background="light" elevation={2}> */}
                {children}
                {/* </Paper> */}
                {/* </Box> */}
            </Container>
        </div>
    )
}

AppPanel.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppPanel)
