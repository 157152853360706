import React from "react"
import Head from "next/head"

import { SKIP_BANNER_PNG } from "@data/images"

export default function Metatags({
    title = "S-K-I-P",
    subtitle = "#skiptothegoodbit",
    description = "find out what’s worth your time",
    image = "",
    path = "",
}) {
    const metaTitle = subtitle ? `${title} | ${subtitle}` : title
    const metaImage = (image || SKIP_BANNER_PNG).replace(/^https?:/, "")
    const pageUrl = `//s-k-i-p.com/${path}`

    return (
        <Head>
            <title>{metaTitle}</title>

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@sttgbit" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={metaImage} />

            <meta property="og:site_name" content="S-K-I-P" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={metaImage} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={pageUrl} />

            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
            />
        </Head>
    )
}
